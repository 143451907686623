import { useCallback, useEffect, useRef, useState } from 'react';
import './Carousel.scss';

import Slide from './Slide';

const SLIDE_TIMER = 5000; // Five seconds;

function Carousel(props) {
    const [direction, setDirection] = useState('left');
    const containerRef = useRef();
    const slideTimer = useRef(false);

    function nextSlide(useDirection) {
        if (!containerRef.current) return;

        const container = containerRef.current;
        const firstSlide = container.firstChild;

        if (useDirection === "left") {
            container.removeChild(firstSlide);
            container.appendChild(firstSlide);
        } else {
            const lastSlide = container.lastChild;
            container.removeChild(lastSlide);
            container.insertBefore(lastSlide, firstSlide);
        }
    }

    const setSlideTimer = useCallback(() => {
        slideTimer.current = setInterval(() => {
            nextSlide(direction)
        }, SLIDE_TIMER);
    }, [direction]);

    function leftClick(evt) {
        setDirection('left');
        nextSlide('left');
    }

    function rightClick(evt) {
        setDirection('right');
        nextSlide('right');
    }

    useEffect(() => {
        setSlideTimer(direction);

        return () => {
            clearInterval(slideTimer.current);
        }
    }, [direction, setSlideTimer]);

    return (
        <div className="slideManager">
            <button
                className="leftArrow"
                onClick={leftClick}
            >
                &lt;
            </button>
            <ul className="slideContainer" ref={containerRef}>
                {props.images.map(image => { return <Slide key={image} imageSource={image} /> })}
            </ul>
            <button
                className="rightArrow"
                onClick={rightClick}
            >
                &gt;
            </button>
        </div>
    )
}

export default Carousel