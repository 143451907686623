import { useState } from 'react';

import './App.scss';
import Carousel from './Carousel/Carousel';

function App() {
  const [middleNav, setMiddleNav] = useState('gallery');

  const PICTURES = [
    '/pictures/General/SylviaAndI.jpg',
    '/pictures/General/Photo on 2012-12-09 at 16.51.jpg',
    '/pictures/General/DSC_1153_B.1.2.DC.jpg',
    '/pictures/Sake Dippers/SakeDippers05.jpg',
    '/pictures/Sake Dippers/SakeDippers04.jpg',
    '/pictures/Sake Dippers/SakeDippers01.jpg',
    '/pictures/Sake Dippers/SakeDippers03.jpg',
    '/pictures/Sake Dippers/SakeDippers02.jpg',
    '/pictures/Bad Habits/DSC00362.JPG',
    '/pictures/Bad Habits/DSC00349.JPG',
    '/pictures/Bad Habits/DSC00361.JPG',
    '/pictures/Bad Habits/DSC00360.JPG',
    '/pictures/Bad Habits/DSC00348.JPG',
    '/pictures/Bad Habits/DSC00358.JPG',
    '/pictures/Bad Habits/DSC00359.JPG',
    '/pictures/Bad Habits/DSC00357.JPG',
    '/pictures/Bad Habits/DSC00356.JPG',
    '/pictures/Bad Habits/DSC00354.JPG',
    '/pictures/Bad Habits/DSC00355.JPG',
    '/pictures/Bad Habits/DSC00351.JPG',
    '/pictures/Bad Habits/DSC00350.JPG',
    '/pictures/Bad Habits/DSC00352.JPG',
    '/pictures/Bad Habits/DSC00353.JPG',
    '/pictures/All Night Music/DSCN0021.JPG',
    '/pictures/All Night Music/DSCN0034.JPG',
    '/pictures/All Night Music/DSCN0020.JPG',
    '/pictures/All Night Music/DSCN0022.JPG',
    '/pictures/All Night Music/mriehle.jpg',
    '/pictures/All Night Music/DSCN0033.JPG',
    '/pictures/All Night Music/DSCN0027.JPG',
    '/pictures/All Night Music/DSCN0044.JPG',
    '/pictures/All Night Music/DSCN0045.JPG',
    '/pictures/DoTheHustleMeOnBass.jpeg',
    '/pictures/Hollywoods Attractions/294271_10150365991153959_1796203845_n.jpg',
    '/pictures/The Gravity/Canton-2017-11-10_07.jpg',
    '/pictures/The Gravity/Canton-2017-11-10_06.jpg',
  ];

  function setCrypto() {
    setMiddleNav('crypto');
  }

  function setGallery() {
    setMiddleNav('gallery');
  }

  function setBandManager() {
    setMiddleNav('bombay');
  }

  function renderMiddleNav() {
    switch (middleNav) { // Note that each case *returns*, so breaks are not necessary.
      case 'crypto':
        return (
          <article>
            <h3>Crypto Currency Exchange Listing (ReactJS)</h3>
            <p>
              My first effort at a project learning React.  It's not the prettiest, but it's functional.
              I'm unlikely to update it any time soon, but who knows.
            </p>

            <object className="currency-exchange" type="text/html" height='600px' width='500px' data="https://mriehle.com/CurrencyExchange">Currency Exchange Listing</object>
          </article>
        );

      case 'bombay':
        return (
          <article>
            <h3>Band Manager Project</h3>
            <p>I've been working on.  It's still very much in progress, but you should be able to login using admin/admin</p>
            <object className="currency-exchange" type="text/html" height='600px' width='900px' data="https://mriehle.com/bombay_client">Band Management System</object>
          </article>
        );

      default:
        return (
          <article>
            <h3>A Gallery of images from bands and such</h3>
            <Carousel images={PICTURES} />
          </article>
        )
    }
  }

  return (
    <div className="App">
      <h1>Michael C Riehle</h1>
      <h2>My Personal Website</h2>
      <header>
        <section>
          Here you can find some personal information about me including a few projects I've done.
        </section>
      </header>

      <main>
        <section>
          <h3>You can find my resume here:</h3><div><a href="/Michael_Riehle_2022-09.06.pdf">PDF Version</a></div>
        </section>

        <section>
          <nav>
            <button onClick={setGallery}>Gallery</button>
            <button onClick={setBandManager}>Band Manager</button>
            <button onClick={setCrypto}>Crypto Currency</button>
          </nav>

          {renderMiddleNav()}
        </section >

        <section>
          <h3>Some other links</h3>
          <ul>
            <li><a href="https://www.linkedin.com/in/aikimcr/" target="_blank" rel="noreferrer">Linked In</a></li>
            <li><a href="https://codepen.io/aikimcr" target="_blank" rel="noreferrer">Codepen</a></li>
            <li><a href="https://github.com/aikimcr" target="_blank" rel="noreferrer">GitHub</a></li>
          </ul>
        </section>

        <section>
          <h3>I am currently looking for a new position</h3>
        </section>
      </main >
      <footer></footer>
    </div >
  );
}

export default App;
