import React from 'react'

function Slide(props) {
    const fileParts = props.imageSource.split('/');
    fileParts.shift();
    const altText = fileParts.join(' ');

    return (
        <li className="carousel-slide">
            <img src={props.imageSource} alt={altText} />
            <div className="info"></div>
        </li>
    )
}

export default Slide